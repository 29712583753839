import { graphql } from "gatsby"
import React from "react"
import {
  Layout,
  Theme,
  Interfaces,
  PreStyledComponents,
  Link,
  Announcement
} from "@life-without-barriers/react-components"
import { PageAnnouncement, Site } from "@life-without-barriers/gatsby-common"
import WrappedHelmet from "../../components/WrappedHelmet"

const { StandardButton } = PreStyledComponents
const { corporateThemeFull } = Theme
const { Container } = PreStyledComponents

interface Props {
  location: { pathname: string }
  data: {
    site: Site
    announcement: PageAnnouncement
  }
  theme: Interfaces.Theme
}

const Staff = ({
  location,
  data: {
    site: { siteMetadata },
    announcement
  }
}: Props) => {
  return (
    <div>
      <WrappedHelmet
        title={`Staff portal login | ${siteMetadata.title}`}
        description="Life Without Barriers staff can log in to the staff portal to access a range of programs and apps"
        siteUrl={siteMetadata.siteUrl}
        path={location.pathname}
      />
      <Layout theme={corporateThemeFull}>
        <div className="ph3 ph4-ns pv4 pv5-ns">
          <Container className="flex-l justify-between-l">
            <div className="w-100 w-90-l pr3-l">
              <h1 className="ts-display-1 ma0 mb4 fw8">Staff login</h1>
              <div className="copy">
                <p>
                  Life Without Barriers staff can login to the staff portal with
                  your Life Without Barriers email address and password to
                  access a range of programs and apps including email, MyHR,
                  Office365, Expense8 and iSight.
                </p>
                <p>
                  If you need to reset your password, click the Reset Password
                  button below.
                </p>
              </div>
              <div className="mt4 mb2">
                <Link
                  className="link normal pr4"
                  to="https://lifewithoutbarriers60.sharepoint.com/sites/intranet"
                  title="Staff login"
                >
                  <StandardButton
                    className="w-100 w-auto-ns"
                    shade="xdark"
                    theme={corporateThemeFull}
                  >
                    Staff login
                  </StandardButton>
                </Link>
                <Link
                  className="link normal"
                  to="https://aka.ms/sspr"
                  title="Reset password"
                >
                  <StandardButton
                    className="w-100 w-auto-ns"
                    shade="xdark"
                    theme={corporateThemeFull}
                  >
                    Reset password
                  </StandardButton>
                </Link>
              </div>
            </div>
          </Container>
          <Container className="pt5">
            {announcement && (
              <Announcement insideBoxComponent={false} {...announcement} />
            )}
          </Container>
        </div>
      </Layout>
    </div>
  )
}

export default Staff

export const query = graphql`
  {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    announcement: contentfulAnnouncement(page: { eq: "caring-space" }) {
      ...ContentfulAnnouncement
    }
  }
`
